<template>
  <div class="number-input-parser">
    <number-input
      v-model="value.value"
      v-bind="value.props"
      :maxlength="['phone', 'money'].includes(value.subType) ? 20 : 100"
      :type="value.subType"
      show-word-limit
    ></number-input>
    <capital-text
      v-if="value.isCapital"
      :value="value.value"
      class="capital"
    ></capital-text>
  </div>
</template>

<script>
import NumberInput from "./NumberInput.vue";
import CapitalText from "./CapitalText.vue";
export default {
  name: "NumberInputParser",
  props: {
    value: Object,
  },
  components: { NumberInput, CapitalText },
};
</script>

<style lang="scss" scoped>
.number-input-parser {
  .capital {
    margin-top: 8px;
  }
}
</style>