<template>
  <div class="date-time-picker-parser">
    <el-date-picker
      type="months"
      v-model="value.value"
      value-format="yyyy-MM"
      @change="handleChange"
      :picker-options="pickerOptions"
      placeholder="选择一个或多个月">
    </el-date-picker>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: "DateMonthsPickerParser",
  props: {
    value: Object,
    list: Array,
    parentId: String
  },
  data() {
     return {
      pickerOptions: {
        disabledDate(time) {
          return (
            moment(time).isSameOrAfter(moment(), 'months') ||
            (moment().diff(moment(time), 'months', true) > (moment().month() === 1 ? 13 : 12))
          )
        },
      },
     }
  },
  methods:{
    handleChange (val) {
      if (val && val.length) {
        for (let i = 0; i < val.length; i++) {
          if (this.value.disabledMonths.includes(val[i])) {
            this.$message.error(`不能选择${val[i]}`)
            this.value.value = []
            return
          }
        }
        this.value.value = val
        const userInfo = JSON.parse(localStorage.userInfo)
        if (this.value.myChildrenType == "multiMonthChildren" && this.value.myName == "months") {
          let currentItemIndex = this.list.findIndex(item => item.id === this.parentId)
          if (currentItemIndex || currentItemIndex === 0) {
            let currentChild = this.list[currentItemIndex].children
            if (currentChild) {
              let shopCode = currentChild.find(item => item.myName === 'shop').value.value
              let cardType = currentChild.find(item => item.myName === 'cardType').value.value
              let month = val.join(',')
              if (userInfo.marketCode && shopCode && cardType && month) {
                let params = {
                  marketCode: userInfo.marketCode,
                  shopCode,
                  cardType,
                  month
                }
                this.$api.dynamicForm.cardRefundInfo(params).then(res => {
                  if (res.code === 200) {
                    if (currentItemIndex || currentItemIndex === 0) {
                      let currentInfoIndex = this.list[currentItemIndex].children.findIndex(item => item.myName === 'refundAccountInfo')
                      if (currentInfoIndex || currentInfoIndex === 0) {
                        this.list[currentItemIndex].children[currentInfoIndex].value = res.data || []
                      }
                    }
                  }
                })
              }
            }
          }
        }
      } else {
        this.value.value = []
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.date-time-picker-parser {
  .date-time-picker {
    width: 100%;
  }
}
</style>