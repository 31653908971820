<template>
  <div>
    <el-table :data="value.value || []" style="height: auto;">
      <el-table-column :align="item.align || 'left'" :key="item.prop" :label="item.label" :prop="item.prop"
        :width="item.width || ''" v-for="(item, index) in value.columnList">
        <template slot-scope="scope">
          <div> {{ scope.row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "ParseTable",
  props: {
    value: Object,
  },
  data () {
    return {
      tableData:[]
    }
  }
};
</script>

<style lang="scss" scoped>
</style>