<template>
  <div class="component-wrapper" :class="isActive ? 'active' : ''">
    <div class="title" v-if="_isShowTitle">
      <span style="color: red" v-if="required">*</span>{{ title }}
    </div>
    <slot></slot>
    <div style="clear: both;"></div>
  </div>
</template>

<script>
export default {
  name: "ComponentWrapper",
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isShowTitle: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    _isShowTitle() {
      return this.isShowTitle;
    },
  },
};
</script>

<style lang="scss" scoped>
$blue: #0089ff;

@mixin after($color) {
  // &::after {
  //   content: "";
  //   height: 100%;
  //   width: 4px;
  //   background-color: $color;
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  // }
}

.component-wrapper {
  // cursor: grab;
  background-color: white;
  padding: 8px 16px;
  min-height: 36px;

  &:hover {
    @include after(lightgray);
  }

  .title {
    margin-bottom: 8px;
  }
}

.active {
  position: relative;
  @include after($blue);
  box-shadow: 0 1px 10px 0 rgba(226, 226, 226, 0.5);

  &:hover {
    @include after($blue);
  }
}
</style>